/* .MuiOutlinedInput-root{
    outline: 1px solid #005a9a;
} */
/* .fieldset.MuiOutlinedInput-notchedOutline {
    border-color: '#005a9a';
    border-width: '1px'
} */
& fieldset.MuiOutlinedInput-notchedOutline{
    border-color: '#005a9a';
    border-width: '1px'
}
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@200&display=swap');
/* .css-fm5xnd-MuiModal-root-MuiDrawer-root .MuiDrawer-paper {
    color: white !important;
    background-color: #005e9f;
} */
/* .css-5091vj-MuiPaper-root-MuiDrawer-paper {
    color: white !important;
    background-color: #005e9f;
} */
/* .css-t4hv1y-MuiTypography-root {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.334em;
    font-family: 'Poppins',sans-serif;
    color: #005e9f !important;
    margin-top: auto;
    margin-bottom: auto;
}
.css-onexqs-MuiTypography-root {
    margin: 0;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.334em;
    font-family: 'Poppins',sans-serif;
    color: #005e9f !important;
} */
/* .css-qrltoc-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    color: #005e9f;
    background-color: #ede7f6;
}
.css-1gqf299-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background: #ffc700;
    color:#005e9f;
}
.css-1gqf299-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background: #ffc700;
    color: #00326d;
} */

.css-1gqf299-MuiButtonBase-root-MuiListItemButton-root:hover {
}

/* .css-1gqf299-MuiButtonBase-root-MuiListItemButton-root:hover {
    background-color: #ffc700;
    color:blue;
}

.css-1gqf299-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    color: blue;
    background-color:#ffc700;
} */

.css-1gqf299-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background-color:#ffc700;
}

.css-1gqf299-MuiButtonBase-root-MuiListItemButton-root:hover {
    text-decoration: none;
    background-color: #ffc700;
}
.css-hl9yao{
    background-color: #efefef;
}
.css-w77gx8{
    background-color: #efefef;
}


/* build */
.css-qbkou.Mui-selected {
    background: #ffc700;
    color: #00326d;
}


.css-qbkou.Mui-selected:hover {
    background: #ffc700;
    color: #00326d;
}
/* submenu */
.css-1ip9haa.Mui-selected {
    background: #ffc700;
    color: #00326d;
}
.css-1ip9haa.Mui-selected:hover{
    background: #ffc700;
    color: #00326d;
}

